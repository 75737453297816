import {useState, useEffect} from "react";

const useVideoPlayer = (videoElement) => {
    const [playerState, setPlayerState] = useState({
        isPlaying: false,
        progress: 0,
        speed: 1,
        isMuted: true,
    });

    const togglePlay = () => {
        setPlayerState({
            ...playerState,
            isPlaying: !playerState.isPlaying,
        });
    };

    useEffect(() => {
        try{

            if(videoElement && videoElement.current){
                playerState.isPlaying ?
                    setTimeout(()=>{videoElement.current?.play()}, 100)  :
                    setTimeout(()=>{videoElement.current?.pause()}, 100) ;
            }
        }catch (e){
            console.log(e)
        }
    }, [playerState.isPlaying, videoElement]);

    const handleOnTimeUpdate = () => {
        if (videoElement && videoElement.current && videoElement !== undefined && videoElement !== null ){
            const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
            setPlayerState({
                ...playerState,
                progress,
            });
            if (progress === 100)
                setPlayerState({...playerState, isPlaying: false});
        }

    };

    const handleVideoProgress = (event) => {
        const manualChange = Number(event.target.value);
        if (videoElement && videoElement.current && videoElement !== undefined && videoElement !== null ) {
            videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
            setPlayerState({
                ...playerState,
                progress: manualChange,
            });
            if (event) {
                setPlayerState({...playerState, isPlaying: true});
            }
        }
    };

    return {
        playerState,
        setPlayerState,
        togglePlay,
        handleOnTimeUpdate,
        handleVideoProgress,
    };
};

export default useVideoPlayer;