import React, {useRef, useState, useEffect} from "react";
import "./player.scss";
import useVideoPlayer from "./useVideoPlayer";
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {useParams, useSearchParams} from 'react-router-dom';
import {Message} from 'primereact/message';

const Player = () => {




    const [searchParams, setSearchParams] = useSearchParams();

    const videoElement = useRef(null);
    // const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    // const [mobile, setMobile] = useState('');

    const {playerState, setPlayerState, togglePlay, handleOnTimeUpdate, handleVideoProgress} =
        useVideoPlayer(videoElement);

    // const [loadComplete, setLoadComplete] = useState(false)
    const [muted, setMuted] = useState(true)
    const togglePlayAnUnmute = () =>{
        if (muted){
            if (loadEvent !== null && loadEvent !== undefined && loadEvent){
               loadEvent.source.postMessage({'title':'click'}, loadEvent.origin)
            }
        }
        setMuted(false);
        togglePlay()
    }

    const [data, setAllData] = useState([]);
    const [formField, setFormField] = useState({});
    const [videoList, setData] = useState({});
    const [videoItem, setVideo] = useState('');
    const [font, setFont] = useState('');
    const [color, setColor] = useState("#095790");
    const [projectId, setProjectId] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const showVideoItem = (item, index) => {
        setLoading(true)
        setPlayerState({...playerState, progress: 0, isPlaying: false});
        const i = data.find(x => x.step_index === item.question_id);
        setFont(i.project.font);
        setProjectId(i.project_id);
        document.getElementById(item.result + index).className = "animation-action item";
        if (i) {
            setTimeout(() => {
                setLoading(false)
                setPlayerState({...playerState, progress: 0, isPlaying: true});
                setData(i);
                setVideo(i.video);
            }, 500);
        } else {
            setLoading(false);
            alert('notFound');
        }
    }

    const [fieldValue, setFieldValue] = useState({})
    const [messageDetails, setMessageDetails] = useState({"status": undefined, "text": ""})
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        setLoading(true);
        if (fieldValue) {
            // const data = {
            //     email: email,
            //     name: name,
            //     mobile: mobile
            // }
            await fetch(`https://api.dcmarketing.ca/api/registers/${projectId}`, {
                method: 'POST',
                body: JSON.stringify(fieldValue),
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                setLoading(false);
                setMessageDetails({"status": "success", "text": "We received your information :)"})

            }).catch((e) => {
                setLoading(false);
                setMessageDetails({"status": "error", "text": "Error in sending message to server"})
            });
        } else {
            setLoading(false);
            setMessageDetails({"status": "error", "text": 'fill all fields is required'})
        }
    }

    const handleShowForm = async () => {
        setShowForm(true);
        setTimeout(() => {
            document.getElementById('form').style.fontFamily = font;
        }, 200);
    }

    const [isThumb, setIsThumb] = useState(false)
    const params = useParams();
    const [loadEvent, setLoadEvent] = useState(null);
    useEffect(() => {
        // setLoading(true);
        fetch(`https://api.dcmarketing.ca/api/output/${params.id}`)
            .then(results => results.json()).then(data => {
                if (data !== undefined && data !== null) {
                    if (searchParams){
                        if (searchParams.get("color") && searchParams.get("color").length > 0){
                            setColor("#" + searchParams.get("color"))
                        }
                        if (searchParams.get("thumb") && searchParams.get("thumb").length > 0){
                            setIsThumb(searchParams.get("thumb") === "true")
                        }
                    }
                    setFormField(data.fields);
                    if(data.videos !== undefined &&
                        data.videos !== null &&
                        data.videos.length > 0){

                        setAllData(data.videos);
                        setData(data.videos[0]);
                        setFont(data.videos[0].project.font);

                        setVideo(data.videos[0]?.video);
                        setProjectId(data.videos[0].project_id);
                        setTimeout(() => togglePlay(), 500);
                    }
                }
            });
        window.addEventListener('message', event => {
            // console.log(event.origin, event.data); // 'Hello from parent!'
            if(event.data["title"] && event.data["title"] === "load" ){
                setLoadEvent(event)
            }
            if(event.data["title"] && event.data["title"] === "play" ){
                setMuted(false)
                playerState.progress = 0
                videoElement.current.currentTime = 0
                setPlayerState(playerState)
                setIsThumb(false);
                // handleVideoProgress()
                setTimeout(()=>{
                    if(videoElement && videoElement.current){

                        // videoElement.current.pause();
                        // videoElement.current.play();
                        togglePlay()
                    }
                    // videoElement.current?.load()
                },1000)
            }
        });

    }, [setLoadEvent]);


    // window.addEventListener("click", function (e) {
    //
    // })



    const Alphabets = ["A","B","C","D","E","F","G","H","I","J","K"]
    return (
        <div >
            {
                isLoading ? (
                    <div className="parent-loader">
                        <span className="loader"></span>
                    </div>
                ) : (
                    <div className="w-100">
                        {
                            showForm ? (
                                <div id="form"
                                     className="border border-1 w-100 rounded-3 p-4 h-auto d-flex align-items-center">

                                    <form onSubmit={handleSubmit} className="w-100 p-2" autoComplete="off">
                                        <div className={"row"}>
                                            <div className={"col-12 text-center"} style={{"marginBottom": "1.3rem"}}>
                                                <h1>Please fill this form</h1>
                                            </div>
                                        </div>

                                        <div className="mt-2">
                                            <InputText
                                                className={ "p-d-block w-100"}
                                                id="Name"
                                                value={fieldValue["name"]}
                                                placeholder={"Your Name ..."}
                                                onChange={e => setFieldValue({...fieldValue, name: e.target.value})}/>
                                        </div>

                                        <div className="mt-2">
                                            <InputText
                                                className={ "p-d-block w-100"}
                                                id="Email *"
                                                value={fieldValue["email"]}
                                                placeholder={"Your Email ..."}
                                                onChange={e => setFieldValue({...fieldValue, email: e.target.value})}/>
                                        </div>

                                        <div className="mt-2">
                                            <InputText
                                                className={ "p-d-block w-100"}
                                                id="Mobile"
                                                value={fieldValue["mobile"]}
                                                placeholder={"Your Mobile ..."}
                                                onChange={e => setFieldValue({...fieldValue, mobile: e.target.value})}/>
                                        </div>
                                        {
                                            formField && formField.map(function(field){
                                                if (field.field_name === null ||
                                                    field.field_name === undefined ||
                                                    field.field_name === ""){
                                                    return <></>
                                                }
                                                let id = field.field_name ||  field.id.toString()
                                                return(
                                                    <div className="mt-2">
                                                        <InputText
                                                            className={ "p-d-block w-100" }
                                                            id={field.id}
                                                            value={fieldValue[id]}
                                                            placeholder={field.field_name}
                                                            onChange={e => setFieldValue({...fieldValue, [id]: e.target.value})}/>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="mt-3 d-flex justify-content-center">
                                            <div className={"row text-center"}>
                                                <div className={"col-12"}>
                                                    <Button
                                                        label={videoList?.project?.submit_button}
                                                        className={"button-36"}
                                                        color={color}
                                                        style={{
                                                            "width": "80%",
                                                            "marginBottom": "1rem",
                                                            fontSize: "large",
                                                            "background":color
                                                        }}/>
                                                </div>
                                                <div className={"col-12"}>
                                                    <Message severity={messageDetails.status}
                                                             text={messageDetails.text}/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            ) : (
                                <div className="w-100 h-100 video-body"  onClick={togglePlayAnUnmute}>
                                    <div className="video-wrapper">

                                        <div className="actions" style={{"display":isThumb ? "none": "block"}}>
                                            <button onClick={togglePlayAnUnmute}>
                                                {
                                                    !playerState.isPlaying ? (<i className="fa fa-play"></i>) : (
                                                        <i className="fa fa-pause"></i>)
                                                }
                                            </button>
                                        </div>

                                        <div className="controls" style={{"display":isThumb ? "none": "flex"}}>
                                            <input type="range"
                                                   style={{"background":color}}
                                                   min="0" max="100" value={playerState.progress}
                                                   onChange={(e) => handleVideoProgress(e)}/>
                                        </div>

                                        <video
                                            autoPlay={isThumb}
                                            style={{height: '100vh', width: '100%', objectFit:"cover",objectPosition:"center"}}
                                            src={videoItem}
                                            muted={muted}
                                            loop={isThumb}
                                            ref={videoElement}
                                            onTimeUpdate={handleOnTimeUpdate}
                                        />

                                        <div className="itemBox" style={{"display":isThumb ? "none": "flex"}}>

                                            {
                                                videoList && videoList?.response?.map((item, index) =>
                                                    (
                                                        (item?.question_id === 0) ? (
                                                            <div className="register" key={index}>
                                                                <Button
                                                                    onClick={handleShowForm}
                                                                    label="Register Form"
                                                                    className="button-36"
                                                                    style={{"background":color}}/>
                                                            </div>
                                                        ) : (
                                                            <div className={"insideItemBox"} key={index}>
                                                                <button id={item.result + (index - 1)} className={"item"}
                                                                        key={index}
                                                                        onClick={() => showVideoItem(item, index - 1)}>
                                                                    <span className="num">{Alphabets[index]}</span>
                                                                    <span className="title">{item.result}</span>
                                                                </button>
                                                            </div>
                                                        )
                                                    )
                                                )
                                            }

                                        </div>

                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }

            <div className="logo"  >
                <img src={require('./logo.jpg')} alt="logo"/>
            </div>
        </div>
    );
};

export default Player;