import Player from "./components/Player";
import {Route, Routes} from "react-router-dom";

function App() {

    return (
        <Routes>
            <Route index path="/video-ask/:id" element={<Player/>}/>
        </Routes>
    )
}

export default App;
