import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter } from 'react-router-dom';

// import stopReportingRuntimeErrors from "react-error-overlay";

const root = ReactDOM.createRoot(document.getElementById('root'));


// if (process.env.NODE_ENV === "development") {
//   stopReportingRuntimeErrors(); // disables error overlays
// }



root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);
reportWebVitals();
